<template>
  <div class="d-flex flex-column">
    <VAutocomplete
      class="mr-4 mb-4 hub-search"
      color="success"
      dense
      hide-details
      hide-no-data
      item-text="title"
      :items="searchedHubs"
      :loading="isLoading"
      outlined
      placeholder="Введите название метки или выберите"
      return-object
      :search-input.sync="searchedText"
      @input="selectHub"
    />
    <div class="d-flex flex-wrap mb-4">
      <VChip
        v-for="hub in hubs"
        :key="hub.id"
        class="mr-4 mb-1"
        close
        color="black"
        label
        text-color="white"
        @click:close="deselectHub(hub)"
      >
        {{ hub.title }}
      </VChip>
    </div>
  </div>
</template>

<script>
import { fetchHubs } from '@/api/api';

export default {
  props: {
    selectedHubs: {
      type: Array,
    },
  },
  emits: ['input'],
  data() {
    return {
      hubs: this.selectedHubs || [],
      isLoading: false,
      searchedText: null,
      searchedHubs: [],
    };
  },
  methods: {
    selectHub(hub) {
      const isContainsHub = this.hubs.some((hubTmp) => hubTmp.hub_id === hub.id);
      if (!isContainsHub) {
        this.hubs.push(hub.deserialized);
        this.$emit('input', this.hubs);
      }
      this.searchedText = null;
    },
    deselectHub(hub) {
      const index = this.hubs.indexOf(hub);
      this.hubs.splice(index, 1);
      this.$emit('input', this.hubs);
    },
    updateHubs() {
      if (!this.searchedText) {
        this.searchedHubs = [];
        this.isLoading = false;
      } else {
        this.isLoading = true;
        fetchHubs(this.searchedText).then((hubs) => {
          this.searchedHubs = hubs.data;
          this.isLoading = false;
        });
      }
    },
  },
  watch: {
    selectedHubs(newSelectedHubs) {
      this.hubs = newSelectedHubs;
    },
    searchedText() {
      this.updateHubs();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

</style>
