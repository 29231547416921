<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <VTextField
        v-model="newSynonym"
        class="text-field mb-3 mr-3"
        color="success"
        dense
        hide-details
        outlined
        placeholder="Введите название синонима"
        required
      />
      <VBtn
        class="default-btn button__add"
        color="#4caf50"
        dark
        :disabled="!newSynonym"
        rounded
        @click="addSynonym"
      >
        Добавить
      </VBtn>
    </div>
    <span class="input-description mb-4">
      Можно выбрать более одной категории,
      если специализация заведения попадает в несколько категорий.
      Заведение будет отображаться в каждой из категорий.
    </span>
    <div class="d-flex flex-wrap mb-4">
      <VChip
        v-for="(synonym, index) in synonyms"
        :key="index"
        class="mr-4 mb-1"
        close
        color="black"
        label
        text-color="white"
        @click:close="deselectSynonym(synonym)"
      >
        {{ shortenSynonym(synonym) }}
      </VChip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SynonymSelection',
  props: {
    value: [Array],
  },
  emits: ['input'],
  data() {
    return {
      newSynonym: null,
      synonyms: this.value ? this.value : [],
      maxSynonymLength: 25,
    };
  },
  methods: {
    addSynonym() {
      const isContainsSynonym = this.synonyms.includes(this.newSynonym);
      if (!isContainsSynonym) {
        this.synonyms.push(this.newSynonym);
        this.$emit('input', this.synonyms);
      }
      this.newSynonym = null;
    },
    deselectSynonym(synonym) {
      const index = this.synonyms.indexOf(synonym);
      this.synonyms.splice(index, 1);
      this.$emit('input', this.synonyms);
    },
    shortenSynonym(value) {
      return value.length > this.maxSynonymLength ? `${value.substring(0, this.maxSynonymLength)}...` : value;
    },
  },
  watch: {
    value(newSynonyms) {
      this.synonyms = newSynonyms;
    },
    searchedText() {
      this.updateCategories();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

.button__add {
  width: 140px;
}
</style>
