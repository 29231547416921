<template>
  <div class="d-flex flex-column">
    <VChipGroup
      v-model="selectedState"
      active-class="cost-selected"
      column
      mandatory
      return-object
    >
      <TransitionGroup name="states">
        <VChip
          v-for="(title, state) in getPossibleStates"
          :key="state"
          class="cost-chip justify-center"
          :disabled="disabled"
          :value="state"
          @click="stateSelected"
        >
          {{ title }}
        </VChip>
      </TransitionGroup>
    </VChipGroup>
    <span class="input-description mb-4">
      Статус заведения необходимо согласовать с ответственными лицами
      и обязательно указывать причину.
      При выставлении статусов вся история изменений сохраняется.
    </span>
  </div>
</template>

<script>
import { stateTitles } from '@/serializers/restaurantSerializer';

export default {
  name: 'RestaurantState',
  props: {
    state: [String],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['state-changed'],
  data() {
    return {
      stateTitles,
      selectedState: this.state,
      stateVariations: {
        filling_content: ['on_automation'],
        on_automation: ['active', 'closed_on_schedule'],
        active: [
          'closed_on_schedule',
          'closed_for_price_change',
          'closed_for_repairs',
          'does_not_respond',
          'closed_by_themselves',
          'delivery_does_not_work',
          'not_working_with_us',
          'removed',
        ],
        closed_on_schedule: ['delivery_does_not_work', 'not_working_with_us'],
        closed_for_price_change: ['active', 'closed_on_schedule'],
        closed_for_repairs: ['active', 'closed_on_schedule', 'removed'],
        does_not_respond: ['active', 'closed_on_schedule', 'removed'],
        closed_by_themselves: ['active', 'closed_on_schedule'],
        delivery_does_not_work: ['active', 'closed_on_schedule'],
        not_working_with_us: ['active', 'closed_on_schedule', 'removed'],
        removed: ['active', 'closed_on_schedule'],
      },
    };
  },
  computed: {
    getPossibleStates() {
      const passedStates = {};
      this.stateVariations[this.selectedState].forEach(
        (el) => { passedStates[el] = this.stateTitles[el]; },
      );
      passedStates[this.selectedState] = this.stateTitles[this.selectedState];
      return passedStates;
    },
  },
  methods: {
    stateSelected() {
      this.$nextTick().then(() => {
        const states = {
          newState: this.selectedState,
          oldState: this.state,
        };
        this.$emit('state-changed', states);
      });
    },
  },
  watch: {
    state(newValue) {
      this.selectedState = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.cost-chip {
  color: $default_dark_gray_color;
  font-family: $default_font;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  transition: all .5s;
}
.cost-selected {
  color: white !important;
  background: $default_green_color !important;
}

.states-enter, .states-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.states-leave-active {
  position: absolute;
}
</style>
