<template>
  <div class="d-flex flex-column">
    <VAutocomplete
      class="mr-4 mb-4 category-search"
      color="success"
      dense
      hide-details="auto"
      hide-no-data
      item-text="title"
      :items="searchedCategories"
      :loading="isLoading"
      outlined
      placeholder="Введите название категории или выберите"
      return-object
      :rules="[() => categories.length > 0 || 'Добавьте одну или несколько категорий']"
      :search-input.sync="searchedText"
      @input="selectCategory"
    />
    <span class="input-description mb-4">
      Можно выбрать более одной категории,
      если специализация заведения попадает в несколько категорий.
      Заведение будет отображаться в каждой из категорий.
    </span>
    <div class="d-flex flex-wrap mb-4">
      <VChip
        v-for="category in categories"
        :key="category.id"
        class="mr-4 mb-1"
        close
        color="black"
        label
        text-color="white"
        @click:close="deselectCategory(category)"
      >
        {{ category.title }}
      </VChip>
    </div>
  </div>
</template>

<script>
import { fetchCategories } from '@/api/api';

export default {
  name: 'CategorySelection',
  props: {
    selectedCategories: {
      type: Array,
    },
  },
  emits: ['input'],
  data() {
    return {
      categories: this.selectedCategories || [],
      isLoading: false,
      searchedText: null,
      searchedCategories: [],
    };
  },
  methods: {
    selectCategory(category) {
      const isContainsCategory = this.categories.some((cat) => cat.id === category.id);
      if (!isContainsCategory) {
        this.categories.push(category);
        this.$emit('input', this.categories);
      }
      this.searchedText = null;
    },
    deselectCategory(category) {
      const index = this.categories.indexOf(category);
      this.categories.splice(index, 1);
      this.$emit('input', this.categories);
    },
    updateCategories() {
      if (!this.searchedText) {
        this.searchedCategories = [];
        this.isLoading = false;
      } else {
        this.isLoading = true;
        fetchCategories(this.searchedText).then((categories) => {
          this.searchedCategories = categories;
          this.isLoading = false;
        });
      }
    },
  },
  watch: {
    selectedCategories(newSelectedCategories) {
      this.categories = newSelectedCategories;
    },
    searchedText() {
      this.updateCategories();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

</style>
