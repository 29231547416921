<template>
  <div>
    <CitySelection
      :city="city"
      class="mb-4"
      @city-selected="city = $event"
    />
    <BuildingSelection
      :building-to-select="address"
      class="mb-4"
      @building-selected="buildingSelected"
      @clear="$emit('location-update', null)"
    />
    <div id="leaflet-map" class="map" />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-lasso';
import { mapGetters } from 'vuex';
import { getAddressByLatLong } from '@/api/geo-api';
import iconRestaurant from '@/assets/icon-restaurant.png';
import { Location } from '@/serializers/locationSerializer';
import CitySelection from '@/components/CitySelection.vue';
import BuildingSelection from '@/components/BuildingSelection.vue';

export default {
  name: 'RestaurantAddressSelection',
  components: {
    CitySelection,
    BuildingSelection,
  },
  props: {
    value: [Location],
  },
  emits: ['location-update', 'input'],
  data() {
    return {
      map: null,
      city: null,
      restaurantMarker: null,
      address: null,
    };
  },
  computed: {
    ...mapGetters(['cities']),
  },
  mounted() {
    this.initMap();
  },
  methods: {
    setCityByLocation(location) {
      this.city = this.cities.reduce((init, city) => {
        const contains = city.containsPoint(location.longitude, location.latitude);
        return contains ? city : init;
      }, null);
    },
    initMap() {
      this.map = L.map('leaflet-map', { zoomControl: false });
      L.tileLayer('https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd').addTo(this.map);
      L.control.zoom({ position: 'topright' }).addTo(this.map);
      this.map.on('click', (event) => this.selectLatLng(
        {
          latitude: event.latlng.lat,
          longitude: event.latlng.lng,
        },
      ));
      this.setupMapCenter();
    },
    setupMapCenter() {
      if (this.value && this.value.coordinate.latitude) {
        this.map.setView([this.value.coordinate.latitude, this.value.coordinate.longitude], 14);
      } else if (this.city) {
        this.map.fitBounds(this.city.mappedBorder);
      } else {
        this.map.setView([47.926363, 71.336273], 4);
      }
    },
    createRestaurantMarker(coordinate) {
      const restIcon = L.icon({ iconUrl: iconRestaurant, iconSize: [40, 40] });
      this.restaurantMarker = L.marker(
        [coordinate.latitude, coordinate.longitude], { icon: restIcon },
      ).addTo(this.map);
    },
    selectLatLng(latLng) {
      this.setRestaurantMarker(latLng);
      const coordinate = new Location();
      coordinate.coordinate.latitude = latLng.latitude.toFixed(5);
      coordinate.coordinate.longitude = latLng.longitude.toFixed(5);
      this.$emit('input', coordinate.coordinate);
      this.setAddressByLocation(coordinate);
    },
    setAddressByLocation(coordinate) {
      getAddressByLatLong(
        {
          lat: coordinate.coordinate.latitude,
          long: coordinate.coordinate.longitude,
        },
      ).then((result) => {
        const serialize = {
          ...result[0].address,
          ...result[0].geometry,
        };
        const city = this.cities.filter((el) => el.title === serialize.city)[0];
        this.address = `${serialize.street ? serialize.street : serialize.district} ${serialize.building}`;
        this.city = city;
        const address = coordinate;
        address.text = this.address;
        this.$emit('location-update', address);
      });
    },
    setRestaurantMarker(latLng) {
      if (!this.restaurantMarker) {
        this.createRestaurantMarker(latLng);
      } else {
        this.restaurantMarker.setLatLng([latLng.latitude, latLng.longitude]).update();
      }
    },
    buildingSelected(newBuilding) {
      const long = newBuilding.location.coordinate.longitude;
      const lat = newBuilding.location.coordinate.latitude;
      this.selectLatLng({ latitude: lat, longitude: long });
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$nextTick().then(() => {
            this.setCityByLocation(newValue.coordinate);
            this.setupMapCenter();
            this.setRestaurantMarker(newValue.coordinate);
            this.address = newValue.text;
          });
        }
      },
    },
    city() {
      this.setupMapCenter();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";

.map {
  width: 100%;
  height: 300px;
  z-index: 1;
}
</style>
