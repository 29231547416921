import { Location } from '@/serializers/locationSerializer';

export class GeoBuilding {
  constructor(data) {
    this.city = data ? data.address.city : null;
    this.street = data ? data.address.street : null;
    this.district = data ? data.address.district : null;
    this.building = data ? data.address.building : null;
    this.location = new Location();
    this.location.coordinate.latitude = data ? data.geometry.location.lat : 0;
    this.location.coordinate.longitude = data ? data.geometry.location.long : 0;
  }

  get buildingName() {
    return this.street !== '' ? this.street : this.district;
  }

  get title() {
    return `${this.city}, ${this.buildingName}, ${this.building}`;
  }
}

export const serializeGeoBuilding = (building) => new GeoBuilding(building);

export const serializeBuildingList = (buildings) => buildings.map(serializeGeoBuilding);
