<template>
  <VCombobox
    v-model="selectedBuilding"
    class="building-selection"
    clearable
    color="success"
    dense
    hide-details="auto"
    item-text="title"
    :items="buildings"
    :loading="isLoading"
    outlined
    placeholder="Введите адрес и нажмите Enter"
    return-object
    :rules="[() => !!buildingToSelect || 'Укажите адрес заведения']"
    :search-input.sync="searchedText"
    @change="buildingSelected"
    @click:clear="$emit('clear')"
    @keyup.enter="setBuildings"
  />
</template>

<script>
import { findBuildings } from '@/api/geo-api';

export default {
  name: 'BuildingSelection',
  props: {
    buildingToSelect: {
      default: '',
    },
  },
  emits: ['clear', 'building-selected'],
  data() {
    return {
      isLoading: false,
      buildings: [],
      searchedText: this.buildingToSelect ? this.buildingToSelect : '',
      selectedBuilding: null,
    };
  },
  methods: {
    setBuildings() {
      if (this.searchedText.length > 0) {
        this.isLoading = true;
        findBuildings(this.searchedText)
          .then((buildings) => {
            this.buildings = buildings;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    buildingSelected() {
      if (typeof this.selectedBuilding !== 'object' || this.selectedBuilding === null) { return; }
      this.$emit('building-selected', this.selectedBuilding);
    },
  },
  watch: {
    buildingToSelect(address) {
      this.selectedBuilding = address;
      this.searchedText = address;
    },
  },
};
</script>
