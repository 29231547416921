<template>
  <VContainer class="ml-4" fluid>
    <DeletionPopUp
      v-if="showDeletionPopup"
      :title="deletionTitle"
      @confirm="deletePhoneByType"
    />
    <div
      v-if="showContracts"
      class="contracts-wrapper fixed d-flex flex-row-reverse pa-12 overflow-auto"
    >
      <VCard width="546">
        <div class="red full-width py-3 pl-4 pr-3 d-flex justify-space-between align-center">
          <span class="text-h6 white--text">Договоры</span>
          <VBtn
            icon
            @click="showContracts = false"
          >
            <VIcon color="white" v-text="'mdi-close'"/>
          </VBtn>
        </div>
        <UniversalTable
          class="pa-3"
          :head-rows="restaurantContractRows"
        >
          <template v-slot:tr>
            <tr
              v-for="(contract, index) in contracts"
              :key="index"
              :class="{'active': contract.isCurrent }"
            >
              <td class="pl-3">
                {{ contract.id }}
              </td>
              <td>
                {{ contract.title }}
              </td>
              <td class="text-center">
                {{ `${contract.percent}%` }}
              </td>
              <td class="text-center">
                {{ contract.commissionStartDate }}
              </td>
              <td class="d-flex justify-center align-center">
                <VBtn
                  icon
                  @click="routeToContract(contract.id)"
                >
                  <VIcon
                    color="success darken-1"
                    size="20"
                    v-text="'mdi-chevron-right'"
                  />
                </VBtn>
              </td>
            </tr>
          </template>
        </UniversalTable>
      </VCard>
    </div>
    <UniversalButton
      class="fixed top-y right-x"
      color="green"
      text="Меню"
      x-large
      @click="$router.push({name: 'menu', query: {pk}})"
    />
    <UniversalButton
      class="fixed bottom-y right-x"
      :color="showContracts ? 'black' : 'green'"
      :icon="showContracts ? 'close' : 'file-document-edit-outline'"
      :text="showContracts ? 'Скрыть' : 'Договоры'"
      x-large
      @click="showContracts = !showContracts"
    />
    <VForm ref="form" lazy-validation>
      <VRow>
        <VCol class="d-flex align-center" cols="7">
          <span class="page_title">Новое заведение</span>
        </VCol>
      </VRow>
      <VRow class="mb-6 mt-4" no-gutters>
        <VCol cols="1">
          <VInput class="input-title mt-3">Название</VInput>
        </VCol>
        <VCol
          ref="title"
          class="pr-6"
          cols="6"
        >
          <EditableTitle
            v-model="title"
            placeholder="Название заведения"
            :rules="[(value) => !!value || 'Введите название заведения']"
          />
        </VCol>
      </VRow>
      <VRow class="mb-4" no-gutters>
        <VCol cols="1">
          <div ref="managerEmail" class="input-title mt-3">Менеджер</div>
        </VCol>
        <VCol cols="6">
          <VCombobox
            v-model="managerEmail"
            color="success"
            dense
            hide-selected
            :items="managers"
            outlined
            placeholder="email менеджера"
            :rules="[
              (v) => !!v || 'Введите email менеджера',
              (v) => /.+@.+\..+/.test(v) || 'Введите корректный email',
            ]"
            @update:search-input="findManager"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="1">
          <div ref="categories" class="input-title mt-3">Категория</div>
        </VCol>
        <VCol cols="6">
          <CategorySelection :selectedCategories="categories"/>
        </VCol>
      </VRow>
      <VRow v-if="restaurantChoices" no-gutters>
        <VCol cols="1">
          <div class="input-title mt-3">Служба доставки</div>
        </VCol>
        <VCol cols="6">
          <DeliveryServiceChoices
            v-model="deliveryService"
            :items="restaurantChoices.deliveryChoices"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="1">
          <div class="input-title mt-3">Время OD, мин</div>
        </VCol>
        <VCol cols="6">
          <VTextField
            v-model="timeOD"
            v-mask="'#####'"
            class="time-field"
            color="success"
            dense
            outlined
            :rules="[(value) => !!value || 'Введите время доставки']"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="1">
          <div class="input-title mt-3">Время VD, мин</div>
        </VCol>
        <VCol cols="6">
          <VTextField
            v-model="timeVD"
            v-mask="'######'"
            class="time-field"
            color="success"
            dense
            outlined
            :rules="[(value) => !!value || 'Введите время доставки']"
          />
        </VCol>
      </VRow>
      <VRow
        v-if="restaurantChoices && notificationType"
        class="mb-4"
        no-gutters
      >
        <VCol cols="1">
          <div ref="notificationType" class="input-title mt-3">Оповещение ресторана</div>
        </VCol>
        <VCol cols="6">
          <NotificationTypeSelect
            :items="restaurantChoices.notificationTypes"
            :value="notificationType"
            @update="notificationType = $event"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="1">
          <div class="input-title mt-2 pr-2">Комментарии к заказу</div>
        </VCol>
        <VCol cols="6">
          <VSwitch
            v-model="isCommentsAllowed"
            class="show-comments my-2 ml-1"
            color="white"
            hide-details
            inset
            :ripple="false"
          />
          <div class="input-description mb-4">
            При активном состоянии - отображается поле,
            где пользователи могут оставлять комментарии к заказу
          </div>
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="1">
          <div class="input-title mt-2 pr-2">Не обновлять меню по интеграции</div>
        </VCol>
        <VCol cols="6">
          <VSwitch
            v-model="saveOldMenu"
            class="save-old-menu my-2 ml-1"
            color="white"
            hide-details
            inset
            :ripple="false"
          />
          <div class="input-description mb-6">
            При активном состоянии - поля: название категорий, название,
            описание и картинки блюд не будут обновляться через интеграцию Open-API
          </div>
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="1">
          <div ref="hubs" class="input-title mt-3">Метки</div>
        </VCol>
        <VCol cols="6">
          <HubSelection :selectedHubs="hubs"/>
        </VCol>
      </VRow>
      <VRow v-if="restaurantChoices" no-gutters>
        <VCol cols="1">
          <div ref="paymentTypes" class="input-title mt-3">Способ оплаты</div>
        </VCol>
        <VCol cols="6">
          <PaymentTypesSelection
            v-model="paymentTypes"
            :choices="restaurantChoices.paymentChoices"
          />
        </VCol>
      </VRow>
      <VRow class="mb-4" no-gutters>
        <VCol cols="1">
          <div ref="schedule" class="input-title mt-5">График работы</div>
        </VCol>
        <VCol cols="6">
          <RestaurantScheduleList v-model="schedule"/>
        </VCol>
      </VRow>
      <VRow class="mb-4" no-gutters>
        <VCol cols="1">
          <div ref="logo" class="input-title mt-8">Логотип</div>
        </VCol>
      <ImageUpload
        :allow-preview="false"
        class="cover"
        :contains-title="false"
        description="Скопируйте ссылку на месторасположение логотипа,
                    либо загрузите изображение в формате PNG, JPEG размером 300x300px"
        disable-padding
        :image="logo"
        :image-col="2"
        image-left
        image-type="restaurant"
        required
        wrapperCols="7"
        @update="logo = $event.logo"
      />
      </VRow>
      <VRow class="mb-4" no-gutters>
        <VCol cols="1">
          <div ref="image" class="input-title mt-3">Обложка</div>
        </VCol>
        <ImageUpload
          allow-preview
          class="cover"
          :contains-title="false"
          description="Скопируйте ссылку на месторасположение категории,
                      либо загрузите изображение в формате PNG, JPEG размером 1600x1200px"
          disable-padding
          :image="image"
          image-type="restaurant"
          left-col="4"
          required
          wrapperCols="7"
          @update="image = $event.logo"
        />
      </VRow>
      <VRow class="mb-4" no-gutters>
        <VCol cols="1">
          <div ref="location" class="input-title mt-3">Адрес заведения</div>
        </VCol>
        <VCol cols="6">
          <RestaurantAddressSelection
            :value="locationSerialized"
            @location-update="location = $event"
          />
        </VCol>
      </VRow>
      <VRow
        v-if="pk"
        class="mb-4"
        no-gutters
      >
        <VCol cols="1">
          <div class="input-title mt-3">Статус</div>
        </VCol>
        <VCol cols="6">
          <RestaurantState
            :disabled="stateIsUpdating"
            :state="state"
            @state-changed="updateState"
          />
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="1">
          <div class="input-title mt-3">Синонимы</div>
        </VCol>
        <VCol cols="7">
          <SynonymSelection v-model="synonyms"/>
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="7">
          <VDivider/>
        </VCol>
      </VRow>
      <VRow>
        <VCol class="d-flex align-center" cols="7">
          <span class="page_title">Номера</span>
        </VCol>
      </VRow>
      <VRow class="mb-6 mt-4" no-gutters>
        <VCol cols="7">
          <UniversalTable :head-rows="restaurantPhoneRows">
            <template v-slot:tr>
              <RestaurantPhone
                creating
                has-default
                @phone-event="addPhone"
              />
              <RestaurantPhone
                v-for="(phone, index) in phones"
                :key="phone.number"
                button-text="Обновить"
                :deletable="phones.length > 1"
                has-default
                :phone="phone"
                @confirm-delete="prepareForDeletion"
                @phone-event="updatePhone($event, index)"
                @remove="removePhone(index)"
              />
            </template>
          </UniversalTable>
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="7">
          <VDivider/>
        </VCol>
      </VRow>
      <VRow>
        <VCol class="d-flex align-center" cols="7">
          <span class="page_title">IVR Номера</span>
        </VCol>
      </VRow>
      <VRow class="mb-6 mt-4" no-gutters>
        <VCol cols="7">
          <UniversalTable :head-rows="restaurantIVRPhoneRows">
            <template v-slot:tr>
              <RestaurantPhone
                creating
                @phone-event="addIVRPhone"
              />
              <RestaurantPhone
                v-for="(phone, index) in phonesIVR"
                :key="index"
                button-text="Обновить"
                :phone="phone"
                @confirm-delete="prepareForDeletion($event, 'ivr')"
                @phone-event="updateIVRPhone($event, index)"
                @remove="removeIVRPhone"
              />
            </template>
          </UniversalTable>
        </VCol>
      </VRow>
    </VForm>
    <VRow>
      <VCol class="text-center" cols="7">
        <VBtn
          class="default-btn button-save my-4"
          color="#4caf50"
          height="45"
          rounded
          @click="validate"
        >
          {{ pk ? 'Сохранить' : 'Добавить' }}
        </VBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import RestaurantScheduleList from '@/components/RestaurantScheduleList.vue';
import ImageUpload from '@/components/ImageUpload.vue';
import SynonymSelection from '@/components/SynonymSelection.vue';
import EditableTitle from '@/components/EditableTitle.vue';
import CategorySelection from '@/components/CategorySelection.vue';
import HubSelection from '@/components/HubSelection.vue';
import DeliveryServiceChoices from '@/components/DeliveryServiceChoices.vue';
import PaymentTypesSelection from '@/components/PaymentTypesSelection.vue';
import RestaurantAddressSelection from '@/components/RestaurantAddressSelection.vue';
import RestaurantState from '@/components/RestaurantState.vue';
import RestaurantPhone from '@/components/RestaurantPhone.vue';
import UniversalButton from '@/components/UniversalButton.vue';
import UniversalTable from '@/components/UniversalTable.vue';
import DeletionPopUp from '@/components/DeletionPopUp.vue';
import NotificationTypeSelect from '@/components/NotificationTypeSelect.vue';
import { restaurantPhoneRows, restaurantIVRPhoneRows, restaurantContractRows } from '@/conditions/tablesHeaders';
import {
  createRestaurant,
  fetchRestaurant,
  updateRestaurant,
  updateRestaurantState,
  fetchContracts,
  createRestaurantPhone,
  updateRestaurantPhone,
  deleteRestaurantPhone,
  getUsers,
  fetchRestaurantChoices,
  getRestaurantIVRPhone,
  createRestaurantIVRPhone,
  updateRestaurantIVRPhone,
  deleteRestaurantIVRPhone,
} from '@/api/api';
import { Restaurant, paymentTypes } from '@/serializers/restaurantSerializer';
import { serializeLocation } from '@/serializers/locationSerializer';
import { serializeRestaurantPhone } from '@/serializers/restaurantPhoneSerializer';
import { serializeRestaurantIVRPhone } from '@/serializers/restaurantIVRPhoneSerializer';

export default {
  name: 'RestaurantEdit',
  components: {
    HubSelection,
    RestaurantState,
    RestaurantAddressSelection,
    PaymentTypesSelection,
    DeliveryServiceChoices,
    CategorySelection,
    EditableTitle,
    SynonymSelection,
    RestaurantScheduleList,
    ImageUpload,
    RestaurantPhone,
    UniversalTable,
    UniversalButton,
    DeletionPopUp,
    NotificationTypeSelect,
  },
  data() {
    return {
      restaurantPhoneRows,
      restaurantIVRPhoneRows,
      restaurantContractRows,
      inputWrapperWidth: 10,
      pk: undefined,
      title: '',
      managerEmail: '',
      managers: [],
      contracts: [],
      categories: [],
      deliveryService: null,
      hubs: [],
      paymentTypes,
      schedule: [],
      logo: 'https://dummyimage.com/300x300/000/fff',
      image: 'https://dummyimage.com/1600x1200/000/fff',
      location: null,
      state: null,
      synonyms: [],
      phones: [],
      phonesIVR: [],
      stateIsUpdating: false,
      showContracts: false,
      showDeletionPopup: false,
      deletionTitle: '',
      timeOD: 15,
      timeVD: 30,
      restaurantChoices: null,
      notificationType: 'call',
      phoneToDelete: null,
      phoneToDeleteType: 'simple',
      saveOldMenu: false,
      isCommentsAllowed: true,
    };
  },
  created() {
    fetchRestaurantChoices().then((choices) => {
      this.restaurantChoices = choices;
    });
    if (this.$route.params.pk) {
      fetchRestaurant(this.$route.params.pk).then(this.setRestaurant);
      fetchContracts({ restaurant_id: this.$route.params.pk }).then((contracts) => {
        this.contracts = contracts.results;
      });
      this.getIVRPhoneList();
    }
  },
  computed: {
    locationSerialized() {
      return serializeLocation(this.location);
    },
  },
  methods: {
    setRestaurant(restaurant) {
      this.$nextTick(() => {
        this.pk = restaurant.pk;
        this.title = restaurant.title;
        this.managerEmail = restaurant.managerEmail;
        this.categories = restaurant.categories;
        this.deliveryService = restaurant.deliveryService;
        this.hubs = restaurant.hubs;
        this.paymentTypes = restaurant.paymentTypes;
        this.schedule = restaurant.schedule;
        this.logo = restaurant.logo;
        this.image = restaurant.image;
        this.location = restaurant.location;
        this.state = restaurant.state;
        this.synonyms = restaurant.synonyms;
        this.phones = restaurant.phones;
        this.timeOD = restaurant.timeOD;
        this.timeVD = restaurant.timeVD;
        this.notificationType = restaurant.notificationType;
        this.saveOldMenu = restaurant.saveOldMenu;
        this.isCommentsAllowed = restaurant.isCommentsAllowed;
      });
    },
    routeToContract(contractId) {
      this.$router.push(
        {
          name: 'contracts-edit',
          params: {
            id: contractId,
          },
        },
      );
    },
    validate() {
      const applyValidation = {
        true: () => this.saveRestaurant(),
        false: () => window.scrollTo(0, 0),
      };
      applyValidation[this.$refs.form.validate()]();
    },
    saveRestaurant() {
      const rest = new Restaurant();
      rest.pk = this.pk;
      rest.title = this.title.trim();
      rest.managerEmail = this.managerEmail;
      rest.categories = this.categories;
      rest.hubs = this.hubs;
      rest.logo = this.logo;
      rest.image = this.image;
      rest.deliveryService = this.deliveryService;
      rest.state = this.state;
      rest.location = this.location;
      rest.paymentTypes = this.paymentTypes;
      rest.synonyms = this.synonyms;
      rest.schedule = this.schedule;
      rest.phones = this.phones;
      rest.timeOD = this.timeOD;
      rest.timeVD = this.timeVD;
      rest.notificationType = this.notificationType;
      rest.saveOldMenu = this.saveOldMenu;
      rest.isCommentsAllowed = this.isCommentsAllowed;
      if (this.pk) {
        updateRestaurant(this.pk, rest).then((updatedRest) => {
          this.setRestaurant(updatedRest);
        });
      } else {
        createRestaurant(rest.deserialized).then((newRest) => {
          this.setRestaurant(newRest);
          if (this.phonesIVR.length > 0) {
            this.phonesIVR.forEach((phone) => {
              this.pk = newRest.pk;
              this.addIVRPhone(phone);
            });
          }
          this.$router.push({ name: 'edit_restaurant', params: { pk: newRest.pk } });
        });
      }
    },
    updateState(states) {
      this.stateIsUpdating = true;
      this.state = states.newState;
      updateRestaurantState(this.pk, states.newState).catch(
        (error) => {
          this.state = states.oldState;
          this.stateIsUpdating = false;
          throw new Error(error.response.data.detail);
        },
      ).then(() => {
        this.stateIsUpdating = false;
      });
    },
    findManager(managerEmail) {
      if (managerEmail && managerEmail.length >= 3) {
        getUsers(managerEmail).then((result) => {
          this.managers = result.emails;
        });
      }
    },
    addPhone(newPhone) {
      if (this.pk) {
        const restaurantPhone = serializeRestaurantPhone();
        Object.assign(restaurantPhone, newPhone);
        restaurantPhone.restaurantId = this.pk;
        createRestaurantPhone(restaurantPhone.deserialized).then((phone) => {
          this.phones.push(phone);
        });
      } else {
        this.phones.push(newPhone);
      }
    },
    updatePhone(updatedPhone, index) {
      if (updatedPhone.restaurantId) {
        const restaurantPhone = serializeRestaurantPhone();
        Object.assign(restaurantPhone, updatedPhone);
        updateRestaurantPhone(restaurantPhone.deserialized).then((phone) => {
          Object.assign(this.phones[index], phone);
        });
      } else {
        Object.assign(this.phones[index], updatedPhone);
      }
    },
    removePhone(index) {
      this.phones.splice(index, 1);
    },
    prepareForDeletion(phone, type = 'simple') {
      this.phoneToDelete = phone;
      this.phoneToDeleteType = type;
      this.showDeletionPopup = true;
    },
    deletePhone() {
      deleteRestaurantPhone(this.pk, this.phoneToDelete.id).then(() => {
        this.phones = this.phones.filter(
          (phone) => phone.id !== this.phoneToDelete.id,
        );
      });
    },
    getIVRPhoneList() {
      const restaurantId = this.$route.params.pk;
      if (restaurantId) {
        getRestaurantIVRPhone(restaurantId).then((phones) => {
          this.phonesIVR = phones;
        });
      }
    },
    addIVRPhone(newPhone) {
      if (this.pk) {
        const restaurantPhone = serializeRestaurantIVRPhone();
        Object.assign(restaurantPhone, newPhone);
        restaurantPhone.restaurantId = this.pk;
        createRestaurantIVRPhone(restaurantPhone.deserialized)
          .then((phone) => {
            this.phonesIVR.push(phone);
          });
      } else {
        this.phonesIVR.push(newPhone);
      }
    },
    updateIVRPhone(updatedPhone, index) {
      if (updatedPhone.restaurantId) {
        const restaurantPhone = serializeRestaurantIVRPhone();
        Object.assign(restaurantPhone, updatedPhone);
        updateRestaurantIVRPhone(restaurantPhone.id, restaurantPhone.deserialized).then((phone) => {
          Object.assign(this.phonesIVR[index], phone);
        });
      } else {
        Object.assign(this.phonesIVR[index], updatedPhone);
      }
    },
    removeIVRPhone(index) {
      this.phonesIVR.splice(index, 1);
    },
    deleteIVRPhone() {
      deleteRestaurantIVRPhone(this.phoneToDelete.id).then(() => {
        this.phonesIVR = this.phonesIVR.filter(
          (phone) => phone.id !== this.phoneToDelete.id,
        );
      });
    },
    deletePhoneByType() {
      this.showDeletionPopup = false;
      const deleteByType = {
        simple: () => this.deletePhone(),
        ivr: () => this.deleteIVRPhone(),
      };
      deleteByType[this.phoneToDeleteType]();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";
@import "src/assets/scss/page.scss";

::v-deep {
  .v-text-field__details {
    display: block;
  }
  .titles-thead th {
    border-bottom: 1px solid #E6E0EC !important;
  }
  tr:hover {
    background: inherit !important;
  }
  td {
    height: 64px;
  }
  .active td {
    background-color: #E5FFEA;
  }
  .v-input--is-label-active .v-input--switch__track {
    color: #E43539 !important;
    opacity: 1;
  }
}
.contracts-wrapper {
  width: fit-content;
  right: 0;
}
.fixed {
  position: fixed;
  flex-direction: row-reverse;
  transition: right .4s;
  overflow-y: auto;
  max-height: 98%;
  z-index: 10;
}
.bottom-y {
  bottom: 10px;
}
.right-x {
  right: 10px;
}
.cover {
  margin-left: -10px;
}
.button-save {
  font-size: 16px;
  color: white;
  width: 300px;
}
.time-field {
  min-height: 48px !important;
  font-size: 14px;
  width: 25% !important;
}
</style>
