<template>
  <div class="d-flex flex-column">
    <VChipGroup
      v-model="selectedDeliveryService"
      active-class="cost-selected"
      mandatory
      return-object
    >
      <VChip
        v-for="service in deliveryServiceChoices"
        :key="service"
        class="cost-chip justify-center"
        :value="service"
      >
        {{ service }}
      </VChip>
    </VChipGroup>
    <span class="input-description mb-4">
      Есть два типа доставки: OD (Own Delivery) - доставка Chocofood в расширенном радиусе
      от заведения, VD (Vendor Delivery) - доставка от заведения.
    </span>
  </div>
</template>

<script>
export default {
  name: 'DeliveryServiceChoices',
  props: {
    value: [String],
    items: {
      type: Array,
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedDeliveryService: this.value,
      deliveryServiceChoices: this.items || [],
    };
  },
  watch: {
    value(newValue) {
      this.selectedDeliveryService = newValue;
    },
    selectedDeliveryService(newSelectedDeliveryService) {
      this.$emit('input', newSelectedDeliveryService);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.cost-chip {
  width: 100px;
  color: $default_dark_gray_color;
  font-family: $default_font;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.cost-selected {
  color: white !important;
  background: $default_green_color !important;
}
</style>
