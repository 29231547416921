import Axios from 'axios';
import { serializeBuildingList } from '@/serializers/buildingSerializer';

const geoApi = Axios.create({
  baseURL: '/geo',
  timeout: 30000,
});

export const findBuildings = (address) => geoApi.get(`/geocode/?address=${address}`)
  .then((response) => serializeBuildingList(response.data.data));

export const getAddressByLatLong = (coordinates) => geoApi.get(`/reverse-geocode/?lat=${coordinates.lat}&long=${coordinates.long}`)
  .then((response) => response.data.data);
export default geoApi;
