<template>
  <tr>
    <td
      v-for="restaurantPhone in restaurantPhoneTextfield"
      :key="restaurantPhone.key"
      class="text-left"
    >
      <NamedRuledTextField
        class="pt-2 pb-0 px-0"
        input-width="12"
        :mask="restaurantPhone.mask"
        no-rules
        :placeholder="restaurantPhone.placeholder"
        regular
        solo-disabled
        :text="phoneToEdit[restaurantPhone.key]"
        title=""
        title-width="0"
        :wrapper-width="inputWrapperWidth"
        @update="phoneToEdit[restaurantPhone.key] = $event"
      />
    </td>
    <td v-if="hasDefault">
      <VCheckbox
        v-model.lazy="phoneToEdit.isDefault"
        class="ml-12"
        color="success"
        hide-details
        :value="phoneToEdit[phone.key]"
      />
    </td>
    <td class="phone-action">
      <RoundedButtons
        :creating="creating"
        :deletable="deletable"
        :is-changed="isChanged"
        @apply="emitPhone"
        @delete="emitDeletion"
      />
    </td>
  </tr>
</template>

<script>
import { restaurantPhoneTextfield } from '@/conditions/textFields';
import NamedRuledTextField from '@/components/NamedRuledTextField.vue';
import RoundedButtons from '@/components/RoundedButtons.vue';

export default {
  name: 'RestaurantPhone',
  components: {
    NamedRuledTextField,
    RoundedButtons,
  },
  props: {
    inputWrapperWidth: {
      type: [String, Number],
      default: 12,
    },
    buttonText: {
      type: String,
      default: 'Добавить',
    },
    restaurantId: {
      type: Number,
      default: null,
    },
    phone: {
      type: Object,
      default: () => (
        {
          id: null,
          title: '',
          number: '',
          isDefault: false,
          restaurantId: null,
        }
      ),
    },
    creating: {
      type: Boolean,
      default: false,
    },
    hasDefault: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['confirm-delete', 'remove', 'phone-event'],
  data() {
    return {
      restaurantPhoneTextfield,
      phoneToEdit: JSON.parse(JSON.stringify(this.phone)),
      isChanged: false,
    };
  },
  methods: {
    emitDeletion() {
      if (this.phoneToEdit.id) {
        this.$emit('confirm-delete', this.phoneToEdit);
      } else {
        this.$emit('remove');
      }
    },
    emitPhone() {
      const phone = this.phoneToEdit;
      phone.number = phone.number ? phone.number : '';
      this.$emit('phone-event', this.phoneToEdit);
      if (this.creating) {
        this.phoneToEdit = {
          id: null,
          title: '',
          number: '',
          isDefault: false,
          restaurantId: null,
        };
      }
      this.isChanged = false;
    },
  },
  watch: {
    phone: {
      deep: true,
      handler() {
        this.phoneToEdit = JSON.parse(JSON.stringify(this.phone));
      },
    },
    phoneToEdit: {
      deep: true,
      handler() {
        if (this.phoneToEdit !== this.phone) {
          this.isChanged = true;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">

.phone-action {
  width: 150px;
}
</style>
