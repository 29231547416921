<template>
  <VSelect
    color="success"
    dense
    flat
    hide-details
    item-color="success"
    item-text="title"
    item-value="value"
    :items="notificationChoices"
    label="Выберите тип"
    outlined
    solo
    :value="value"
    @change="setValue"
  />
</template>
<script>

export default {
  props: {
    value: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  emits: ['update'],
  data() {
    return {
      notificationChoices: [],
    };
  },
  created() {
    this.setNotificationChoices();
  },
  methods: {
    setNotificationChoices() {
      const choices = {
        call: 'По звонку',
        esb_hermes: 'Шина через Гермес',
        burger_king: 'Шина для Бургер Кинга',
      };
      if (this.items.length) {
        this.items.forEach((item) => {
          this.notificationChoices.push({ title: choices[item], value: item });
        });
      }
    },
    setValue(val) {
      this.$emit('update', val);
    },
  },
};
</script>

<style lang="scss">

.v-select {
  width: 97%;
}
</style>
