<template>
  <div class="d-flex flex-column">
    <div
      class="d-inline-flex"
      :class="{ 'my-auto': disableDescription }"
    >
      <button
        v-for="paymentType in paymentChoices"
        :key="paymentType"
        class="default-btn mr-3"
        :class="{ active: !selectedPaymentTypes[paymentType]}"
        type="button"
        @click="togglePaymentType(paymentType)"
      >
        <img :alt="paymentType" :src="icons[paymentType]" />
      </button>
    </div>
    <VInput
      v-model="valid"
      hide-details="auto"
      :rules="[(v) => !!v || 'Выберите способы оплаты']"
    />
    <span
      v-if="!disableDescription"
      class="input-description mt-4 mb-6"
    >
      Вы можете выбрать несколько способов оплаты для каждого из заведений.
      При включении “Онлайн-оплаты” оплата бонусами Chocofood включается автоматически.
    </span>
  </div>
</template>

<script>
import { paymentTypeTitles } from '@/serializers/restaurantSerializer';
import bonus from '@/assets/icons/payments/bonus.svg';
import card from '@/assets/icons/payments/card.svg';
import cash from '@/assets/icons/payments/cash.svg';
import rakhmet from '@/assets/icons/payments/rakhmet.svg';

export default {
  name: 'PaymentTypesSelection',
  props: {
    value: [Object],
    disableDescription: {
      type: Boolean,
      default: false,
    },
    disableRequest: {
      type: Boolean,
      default: false,
    },
    choices: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  data() {
    return {
      icons: {
        bonus,
        card,
        cash,
        rakhmet,
      },
      paymentTypeTitles,
      paymentChoices: this.choices,
      selectedPaymentTypes: this.value,
    };
  },
  created() {
    this.setupSelectedPaymentTypes();
  },
  computed: {
    valid() {
      return Object.values(this.selectedPaymentTypes).some((paymentType) => !!paymentType);
    },
  },
  methods: {
    setupSelectedPaymentTypes() {
      if (this.value) {
        this.selectedPaymentTypes = this.value;
      } else {
        this.selectedPaymentTypes = this.paymentChoices
          .reduce((obj, pt) => ({ ...obj, [pt]: false }), {});
      }
    },
    togglePaymentType(paymentType) {
      this.selectedPaymentTypes[paymentType] = !this.selectedPaymentTypes[paymentType];
      this.$emit('input', this.selectedPaymentTypes);
    },
  },
  watch: {
    value() {
      this.setupSelectedPaymentTypes();
    },
    choices: {
      deep: true,
      handler() {
        this.paymentChoices = this.choices;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.default-btn {
  border-color: $default_green_color;
  background: $default_green_color;
  color: $default_dark_gray_color;
  border-radius: 50%;
  padding: 4px;
  width: 32px;
  height: 32px;
}
.active {
  filter: grayscale(100%);
}
</style>
